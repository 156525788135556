<script>
/* eslint-disable */
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import Layout from '../../layouts/main';

/**
 * Form-repeater Component
 */
export default {
  page: {
    title: 'Form Repeater',
    meta: [{name: 'description', content: appConfig.description}],
  },
  components: {Layout, PageHeader},
  data() {
    return {
      title: 'Form Repeater',
      items: [
        {
          text: 'Forms',
          href: '/',
        },
        {
          text: 'Form Repeater',
          active: true,
        },
      ],
      fields: [{id: 1}],
      phoneData: [{id: 1}],
    };
  },

  methods: {
    /**
     * Push the row in form
     */
    AddformData() {
      this.fields.push({
        name: '', email: '', subject: '', message: '',
      });
    },
    /**
     * Delete the row
     */
    deleteRow(index) {
      if (confirm('Are you sure you want to delete this element?')) {
        this.fields.splice(index, 1);
      }
    },
    /**
     * Add the phone number in form
     */
    AddPhoneNo() {
      this.phoneData.push({phone: ''});
    },
    /**
     * Delete the row from form
     */
    deletePhone(index) {
      this.phoneData.splice(index, 1);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Example</h4>
            <form class="repeater" enctype="multipart/form-data">
              <div>
                <div
                  v-for="(field, index) in fields"
                  :key="field.id"
                  class="row"
                >
                  <div class="mb-3 col-lg-2">
                    <label for="name">Name</label>
                    <input
                      id="name"
                      v-model="field.name"
                      class="form-control"
                      name="untyped-input"
                      type="text"
                    />
                  </div>

                  <div class="mb-3 col-lg-2">
                    <label for="email">Email</label>
                    <input
                      id="email"
                      v-model="field.email"
                      class="form-control"
                      type="email"
                    />
                  </div>

                  <div class="mb-3 col-lg-2">
                    <label for="subject">Subject</label>
                    <input
                      id="subject"
                      v-model="field.subject"
                      class="form-control"
                      type="text"
                    />
                  </div>

                  <div class="mb-3 col-lg-2">
                    <label for="resume">Resume</label>
                    <input id="resume" class="form-control-file" type="file"/>
                  </div>

                  <div class="mb-3 col-lg-2">
                    <label for="message">Message</label>
                    <textarea
                      id="message"
                      v-model="field.message"
                      class="form-control"
                    ></textarea>
                  </div>

                  <div class="col-lg-2 align-self-center">
                    <div class="d-grid">
                      <input
                        class="btn btn-primary btn-block"
                        type="button"
                        value="Delete"
                        @click="deleteRow(index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <input
                class="btn btn-success mt-3 mt-lg-0"
                type="button"
                value="Add"
                @click="AddformData"
              />
            </form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Nested</h4>
            <form class="outer-repeater">
              <div class="outer">
                <div class="outer">
                  <div class="mb-3">
                    <label for="formname">Name :</label>
                    <input
                      id="formname"
                      class="form-control"
                      placeholder="Enter your Name..."
                      type="text"
                    />
                  </div>

                  <div class="mb-3">
                    <label for="formemail">Email :</label>
                    <input
                      id="formemail"
                      class="form-control"
                      placeholder="Enter your Email..."
                      type="email"
                    />
                  </div>

                  <div class="inner-repeater mb-4">
                    <div class="inner mb-3">
                      <label>Phone no :</label>
                      <div
                        v-for="(data, index) in phoneData"
                        :key="data.id"
                        class="inner mb-3 row"
                      >
                        <div class="col-md-10 col-8">
                          <input
                            v-model="data.phone"
                            class="inner form-control"
                            placeholder="Enter your phone no..."
                            type="text"
                          />
                        </div>
                        <div class="col-md-2 col-4">
                          <div class="d-grid">
                            <input
                              class="btn btn-primary btn-block inner"
                              type="button"
                              value="Delete"
                              @click="deletePhone(index)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <input
                      class="btn btn-success inner"
                      type="button"
                      value="Add Number"
                      @click="AddPhoneNo"
                    />
                  </div>

                  <div class="mb-3">
                    <label class="d-block mb-3">Gender :</label>

                    <div class="form-check form-check-inline">
                      <input
                        id="inlineRadio1"
                        class="form-check-input"
                        name="gender"
                        type="radio"
                        value="option1"
                      />
                      <label class="form-check-label" for="inlineRadio1"
                      >Male</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        id="inlineRadio2"
                        class="form-check-input"
                        name="gender"
                        type="radio"
                        value="option2"
                      />
                      <label class="form-check-label" for="inlineRadio2"
                      >Female</label
                      >
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="formmessage">Message :</label>
                    <textarea
                      id="formmessage"
                      class="form-control"
                      rows="3"
                    ></textarea>
                  </div>
                  <button class="btn btn-primary" type="submit">Submit</button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
